import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import { createScroom, debug } from "scroom";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "debug"
    }}>{`Debug`}</h1>
    <p>{`The debug tool of Scroom can help you locate the problem quickly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createScroom, debug } from "scroom";

const sc = createScroom(...);

debug(sc)
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'() => {\n  const ref = React.useRef()\n  React.useEffect(() => {\n    const sc = createScroom({\n      target: ref.current,\n    })\n    const debugController = debug(sc)\n    return () => {\n      sc.destroy()\n      debugController.destroy()\n    }\n  }, [])\n  return <div ref={ref} style={{ height: \'300px\' }} />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      createScroom,
      debug,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const ref = React.useRef();
        React.useEffect(() => {
          const sc = createScroom({
            target: ref.current
          });
          const debugController = debug(sc);
          return () => {
            sc.destroy();
            debugController.destroy();
          };
        }, []);
        return <div ref={ref} style={{
          height: "300px"
        }} />;
      }}
    </Playground>
    <br />
    <p>{`You can also provide a debug ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`debug(sc, "your-id");
`}</code></pre>
    <h2 {...{
      "id": "important"
    }}>{`Important`}</h2>
    <p>{`It is not recommended to use debug tool in a production environment. It will affect performance and bundle size.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      